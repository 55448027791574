/**
 * Settings
 */

 $sal-animation-duration: 0.2s !default;
 $sal-animation-delay: 0s !default;
 $sal-slide-offset: 20% !default;
 $sal-zoom-in-scale: 0.1 !default;
 $sal-zoom-out-scale: 1.1 !default;
 $sal-flip-rotate: 91deg !default;
 
 /**
   * Easings
   */
 
 $sal-easings: (linear: linear,
     ease: ease,
     ease-in: ease-in,
     ease-out: ease-out,
     ease-in-out: ease-in-out,
 
     ease-in-cubic: cubic-bezier(.55, .055, .675, .19),
     ease-out-cubic: cubic-bezier(.215, .61, .355, 1),
     ease-in-out-cubic: cubic-bezier(.645, .045, .355, 1),
 
     ease-in-circ: cubic-bezier(.6, .04, .98, .335),
     ease-out-circ: cubic-bezier(.075, .82, .165, 1),
     ease-in-out-circ: cubic-bezier(.785, .135, .15, .86),
 
     ease-in-expo: cubic-bezier(.95, .05, .795, .035),
     ease-out-expo: cubic-bezier(.19, 1, .22, 1),
     ease-in-out-expo: cubic-bezier(1, 0, 0, 1),
 
     ease-in-quad: cubic-bezier(.55, .085, .68, .53),
     ease-out-quad: cubic-bezier(.25, .46, .45, .94),
     ease-in-out-quad: cubic-bezier(.455, .03, .515, .955),
 
     ease-in-quart: cubic-bezier(.895, .03, .685, .22),
     ease-out-quart: cubic-bezier(.165, .84, .44, 1),
     ease-in-out-quart: cubic-bezier(.77, 0, .175, 1),
 
     ease-in-quint: cubic-bezier(.755, .05, .855, .06),
     ease-out-quint: cubic-bezier(.23, 1, .32, 1),
     ease-in-out-quint: cubic-bezier(.86, 0, .07, 1),
 
     ease-in-sine: cubic-bezier(.47, 0, .745, .715),
     ease-out-sine: cubic-bezier(.39, .575, .565, 1),
     ease-in-out-sine: cubic-bezier(.445, .05, .55, .95),
 
     ease-in-back: cubic-bezier(.6, -.28, .735, .045),
     ease-out-back: cubic-bezier(.175, .885, .32, 1.275),
     ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55));
 
 /**
   * Core
   */
 
 [data-sal] {
     transition-duration: $sal-animation-duration;
     transition-delay: $sal-animation-delay;
     transition-duration: var(--sal-duration, $sal-animation-duration);
     transition-delay: var(--sal-delay, $sal-animation-delay);
     transition-timing-function: var(--sal-easing, map-get($sal-easings, 'ease'));
 }
 
 @for $i from 4 through 40 {
     [data-sal][data-sal-duration='#{$i * 50}'] {
         transition-duration: #{$i * 0.05}s;
     }
 }
 
 @for $i from 1 through 20 {
     [data-sal][data-sal-delay='#{$i * 50}'] {
         transition-delay: #{$i * 0.05}s;
     }
 }
 
 @each $key,
 $value in $sal-easings {
     [data-sal][data-sal-easing='#{$key}'] {
         transition-timing-function: $value;
     }
 }
 
 /**
   * Animations
   */
 
 // Fade
 [data-sal|='fade'] {
     opacity: 0;
     transition-property: opacity;
 }
 
 [data-sal|='fade'].sal-animate,
 body.sal-disabled [data-sal|='fade'] {
     opacity: 1;
 }
 
 // Slide
 [data-sal|='slide'] {
     opacity: 0;
     transition-property: opacity, transform;
 }
 
 [data-sal='slide-up'] {
     transform: translateY($sal-slide-offset);
 }
 
 [data-sal='slide-down'] {
     transform: translateY(-$sal-slide-offset);
 }
 
 [data-sal='slide-left'] {
     transform: translateX($sal-slide-offset);
 }
 
 [data-sal='slide-right'] {
     transform: translateX(-$sal-slide-offset);
 }
 
 [data-sal|='slide'].sal-animate,
 body.sal-disabled [data-sal|='slide'] {
     opacity: 1;
     transform: none;
 }
 
 // Zoom
 [data-sal|='zoom'] {
     opacity: 0;
     transition-property: opacity, transform;
 }
 
 [data-sal='zoom-in'] {
     transform: scale($sal-zoom-in-scale);
 }
 
 [data-sal='zoom-out'] {
     transform: scale($sal-zoom-out-scale);
 }
 
 [data-sal|='zoom'].sal-animate,
 body.sal-disabled [data-sal|='zoom'] {
     opacity: 1;
     transform: none;
 }
 
 // Flip
 [data-sal|='flip'] {
     backface-visibility: hidden;
     transition-property: transform;
 }
 
 [data-sal='flip-left'] {
     transform: perspective(2000px) rotateY(-$sal-flip-rotate);
 }
 
 [data-sal='flip-right'] {
     transform: perspective(2000px) rotateY($sal-flip-rotate);
 }
 
 [data-sal='flip-up'] {
     transform: perspective(2000px) rotateX(-$sal-flip-rotate);
 }
 
 [data-sal='flip-down'] {
     transform: perspective(2000px) rotateX($sal-flip-rotate);
 }
 
 [data-sal|='flip'].sal-animate,
 body.sal-disabled [data-sal|='flip'] {
     transform: none;
 }