
.rts-reservation-area{
    background: #1F1F1F;
    form.appoinment-form{
        padding: 60px 0;
        display: flex;
        align-items: end;
        gap: 30px;
        justify-content: center;
        @media(max-width:991px){
            flex-wrap: wrap;
        }
        .single-input{
            display: block;
            width: 25%;
            @media(max-width:991px){
                width: 47%;
            }
            @media(max-width:768px){
                width: 95%;
            }
            &.phone{
                margin-top: 25px;
            }
            &.file{
                width: 100%;
                input{
                    height: unset;
                    padding: 20px;
                }
            }
            label{
                display: block;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 14px;
                margin: 0px 0px 20px 0px;
                .indicator{
                    color: #f00;
                }
            }
            input{
                height: 60px;
                background: none;
                display: block;
                padding: 0 30px;
                border: 1px solid #2B2B2B;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                height: 60px;
                width: 100%;
                max-width: 100%;
                display: block;
            }
        }
        .form-control{
            background: #ffffff;
            border: none;
            height: 120px;
            padding: 20px;
            font-size: 16px;
            &:focus{
                border: none;
                box-shadow: none;
            }
        }
    }
    &.reservation{
        background: #FFFFFF;
        padding: 80px 0;
        form.appoinment-form{
            flex-wrap: wrap;
            justify-content: center;
            @media(max-width:991px){
                justify-content: flex-start;
            }
            .single-input{
                width: 30%;
                margin-bottom: 15px;
                @media(max-width:768px){
                 width: 47%;   
                }
                @media(max-width:576px){
                 width: 100%;   
                }
                label{
                    color: #111111;
                }
                input{
                    border: 1px solid #E8E8E8;
                }
                &.message{
                    width: 95%;
                    textarea{
                        height: 150px;
                        border: 1px solid #E8E8E8;
                    }
                }
                .rts-btn{
                    &:hover{
                        color: #111111;
                        border: 1px solid #111111;
                    }
                }
            }
        }
        
    }
}
.rts-service-area{
    .banner-one-wrapper{
        .title-banner{
            font-size: 50px;
            line-height: 58px;
            margin: 21px 0px 0px 0px;
            transition-delay: .5s;
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 45px;
            }
        }
        p.desc{
            font-size: 18px;
            line-height: 28px;
            margin: 10px 0px 0px 0px;
            transition-delay: .8s;
            @media(max-width:576px){
                br{
                    display: none;
                }
            }
        }
    }
    .service-area-inner{
        .service-wrapper{
            text-align: center;
            position: relative;
            padding: 60px 50px 50px;
            margin: 0 10px;
            overflow: hidden;
            background: #FFFFFF;
            z-index: 3;
            @media(max-width:576px){
                padding: 60px 30px 50px;
            }
            &:hover{
                &::before{
                    background: var(--color-primary);
                }
            }
            &::before{
                content: "";
                width: 95%;
                height: 90%;
                background: #fcefe7;
                position: absolute;
                left: 50%;
                top: -60%;
                transform: translateX(-50%);
                border-radius: 50%;
                transition: all 0.6s;
                z-index: -1;
            }
            .icon{
                width: 120px;
                height: 120px;
                line-height: 120px;
                background: #fff;
                margin: 0 auto 15px auto;
                border-radius: 100%;
                box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.02);
            }
            .content{
                .title{
                    font-size: 30px;
                    line-height: 60px;
                    font-weight: 400;
                    margin-bottom: 3px;
                    @media(max-width:1200px) and (min-width:991px){
                        font-size: 22px;
                        line-height: 40px;
                    }
                    @media(max-width:991px){
                        font-size: 26px;
                        line-height: 40px;
                    }
                }
                .service-txt{
                    margin-bottom: 25px;
                    font-size: 16px;
                    line-height: 25px;
                    @media(max-width:1200px) and (min-width:991px){
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    &.home-five{
        background: #f7f7f7;
        .banner-one-wrapper{
            text-align: center;
        }
    }
}

.ms-woocommerce-cart-form-wrapper {

    .shop_table {
        border: 1px solid #e2e2e2;
        th {
            border-top: none;
            font-size: 16px;
            font-weight: 800;
            color: #000000;
            padding-left: 0;
            text-align: center;
        }
        thead{
            border: none;
            @media(max-width:767px){
                display: none;
            }
        }
        td {
            padding: 25px;
            text-align: center;
            border-left: none;
            border-right: none;
            color: #040404;
            font-weight: 600;
            @media(max-width:767px){
                border: none !important;
            }
        }
        th, td {
            border-left: none;
            border-right: none;
        }
        th{
            padding: 15px 0;
        }
        &.shop_table_responsive{
            border: none;
        }

    }
    .shop_table tr {
        text-align: left;
        border: none;
        @media(max-width:767px){
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 40px;
        }
    }
    .product-remove {
        font-size: 28px;
        @media(max-width:767px){
            width: 100% !important;
            font-size: 34px;
        }
        svg{
            @media(max-width:767px){ 
                width: 32px;
            }
        }
        
    }

    .product-thumbnail {
        width: 20rem;
        @media(max-width:767px){
            width: 100%;
        }

        img {
            width: 80px;
            border-radius: 0;
            @media(max-width:767px){
                width: 100%;
            }
        }

    }

    .product-name {
        font-size: 18px;
        font-weight: 600;
        @media(max-width:767px){
            border: none !important;
            width: 100%;
            text-align: left !important;
            font-size: 22px;
        }
        
        a {
            color: #000000;
            transition: all .3s;
            font-weight: 400;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .product-price{
        @media(max-width:767px){
            width: 50%;
            text-align: left !important;
        }
    }
    .product-subtotal{
        @media(max-width:767px){
            width: 100%;
            text-align: left !important;
        }
    }

    // Remove Btn
    .product-remove {
        width: 3rem;
        padding-left: 0;
        @media(max-width:767px){
            border: none !important;
        }

        svg {
            fill: var(--color-contrast-higher);
            transition: fill .3s cubic-bezier(.645,.045,.355,1);
            border: 1px solid #ddd;
            padding: 3px;
            border-radius: 50%;
            width: 20px;

            &:hover {
                fill: var(--color-error);
            }

        }
    }

    // Qty
    .ms-quantity {
        position: relative;
        width: 8rem;
        display: flex;
        
        .input-text {
            padding: 0.5em;
            height: 45px;
            border: none;
            width: 100%;
            text-align: center;
            color: #000000;
            background-color: #f7f7f7;
            appearance: none;

            &:focus-visible {
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        .button-minus,
        .button-plus {
            border: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            background-color: var(--color-contrast-lower);
            transition: background-color .3s cubic-bezier(.645,.045,.355,1), transform .1s cubic-bezier(.645,.045,.355,1);
            svg {
                width: 20px;
                stroke-width: 2pt;
                stroke: var(--color-contrast-higher);
            }

            &:hover {
                background-color: var(--color-contrast-low);
            }
            &:active {
                transform: translateY(2px);
            }
        }
        .button-minus{
            border-top-left-radius: 6pt;
            border-bottom-left-radius: 6pt;
            border-right: solid 1px var(--color-bg);
            svg {
                margin-left: 1pt;
            }
        } 
        .button-plus {
            border-top-right-radius: 6pt;
            border-bottom-right-radius: 6pt;
            border-left: solid 1px var(--color-bg);
            svg {
                margin-right: 1pt;
            }
        }

    }

    // Cupon & Actions
    .ms-actions-inner {
        display: flex;
        justify-content: space-between;
        @media(max-width:767px){
            display: block;
        }
    }
    .coupon {
        display: inline-flex;
        overflow: hidden;
        background-color: var(--color-contrast-lower);
        @media(max-width:767px){
            width: 100%;
        }

        .button {
            border: 1px solid var(--color-primary);
            padding: 15px 33px;
            color: var(--color-white);
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            line-height: 1;
            @media(max-width:400px){
                width: 50%;
            }
            &:hover{
                border: 1px solid #d3ced2;
                color: #111111;
                background: none;
            }
        }
    }

    .coupon + .button {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: var(--color-primary);
        color: var(--color-white);
        border: none;
        margin-right: 0;
        margin-left: auto;
        line-height: 2.4;
        font-size: 15px;
        border: solid 1px transparent;
        transition: transform .1s cubic-bezier(.645,.045,.355,1);

        &:disabled {
            color: var(--color-contrast-high);
            background-color: transparent;
            border: solid 1px var(--color-contrast-high);
            @media(max-width:767px){
                display: none;
            }
        }

        &:active {
            transform: translateY(2px);
        }
    }
    #coupon_code {
        border: 1px solid #d3ced2;
        padding: 0.5em 1em 0.5em 1em;
        height: 45px;
        width: 320px;
        margin-right: 20px;
        @media(max-width:400px){
            width: 50%;
        }

        &:focus-visible {
            outline: none;
        }
    }

    // Cart Totals
    .ms-cart-totals {
        margin-top: 6rem;
        margin-bottom: 6rem;
        padding: 40px;
        border: 1px solid #E5E5E5;

        .cart-subtotal,
        .order-total {
            td {
                text-align: right;
            }
        }
        tr {
            border-bottom: 1px solid #e2e2e2;
        }

        th, td {
            border: none;
            padding: 30px 10px;
            text-align: start;
            font-size: 16px;
            font-weight: bold;
            color: #777777;
            .woocommerce-Price-amount{
                font-size: 16px;
                font-weight: bold;
                color: #040404;
            }
        }

        .order-total {
            th, td {
                padding: 30px 10px;
            }
        }

        h3 {
            font-size: 30px;
            line-height: 30px;
            color: #111;
            font-weight: 400;
            border-bottom: 1px solid #E6E6E6;
            padding-bottom: 17px;
            margin-bottom: 0;
        }
        .woocommerce-shipping-methods{
            padding-left: 0;
            list-style: none;
        } 
        .woocommerce-shipping-destination {
            margin: 4pt 0;
        }
        .ms-proceed-to-checkout {
            margin-top: 3rem;
            display: flex;
            @media(max-width:767px){
                flex-direction: unset;
            }
            a {
                padding: 0.5em 1.2em 0.5em 1.2em;
                height: 45px;
                color: #ffffff;
                display: flex;
                align-items: center;
                border: 1px solid var(--color-primary);
                &:active {
                    transform: translateY(2px);
                }
                &:hover{
                    color: #040404;
                    border: 1px solid #E6E6E6;
                }
            }
        }

        // Shiping
        .woocommerce-shipping-calculator {
            max-width: 100%;
            display: flex;
            flex-direction: column;

            .shipping-calculator-form {
                text-align: left;
                p {
                    max-width: 100%;
                    padding-top: 15px;

                    .input-text {
                        background-color: #f7f7f7;
                        width: 100%;
                        height: 45px;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-radius: 0;
                        color: #000000;
                        transition: border-color 150ms var(--ease-in-out),box-shadow 150ms var(--ease-in-out),-webkit-box-shadow 150ms var(--ease-in-out);
                        
                        &:hover {
                            border-color: var(--color-primary);
                        }
                        &:focus-visible {
                            z-index: 1;
                            border-color: var(--color-primary);
                            outline: 0;
                        }
                    }

                    .select2-container .select2-selection--single {
                        height: 45px;
                        display: flex;
                        align-items: center;
                        border-radius: 6pt;
                        background-color: var(--color-bg);
                        border: 1px solid #f7f7f7;
                    }
                    .select2-container--default .select2-selection--single .select2-selection__rendered {
                        color: var(--color-contrast-higher);
                    }
                    .select2-container .select2-selection--single .select2-selection__rendered {
                        padding-left: 1rem;
                        width: 100%;
                    }
                    .select2-container--default .select2-selection--single .select2-selection__arrow {
                        height: 45px;
                        right: 8pt;
                    }

                    .button {
                        height: 45px;
                        padding: 0 1rem;
                        border: none;
                        color: #ffffff;
                        border-radius: 6pt;
                        float: right;
                    }
                }

            }

        }
        .shop_table td {
            text-align: right;
        }
    }

}

.home-one.our-chef{
    background: #FFFFFF !important;
}


.ms-main{
    padding: 120px 0;
    .single-product{
        .ms-single-product{
            .left-side-image{
                margin-right: 0;
                img{
                    width: 100%;
                }
            }
            .ms-single-product__content{
                @media(max-width:991px){
                    margin-top: 100px;
                    padding-left: 0;
                }
                .cart{
                    .single_add_to_cart_button{
                        line-height: 42px;
                    }
                }
            }
            .tab-area{
                .nav-tabs{
                    @media(max-width:520px){
                        justify-content: center;
                    }
                    .nav-item{
                        margin-top: 0;
                        margin-bottom: 0;
                        @media(max-width:520px){
                            margin-bottom: 10px;
                        }
                        .nav-link{
                            color: #111111;
                            font-family: "Arapey", sans-serif;
                            font-size: 24px;
                            font-weight: 400;
                            padding: 8pt 20pt;
                            border-color: transparent;
                            border-width: 0 0 1px 0;
                            border-style: solid;
                            &.active{
                                background: none;
                                color: var(--color-primary);
                                border-color: var(--color-primary);
                            }
                        }
                    }
                }
                .tab-content{
                    border-style: solid;
                    border-width: 1px 0 0 0;
                    border-color: #dee2e6;
                    padding: 30pt 0;
                    line-height: 1.8;
                    margin-top: -1px;
                    .tab-pane{
                        p{
                            img{
                                float: right;
                                @media(max-width:576px){
                                    float: unset;
                                    width: 100%;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                        .ms-heading-title{
                            font-size: 24px;
                            font-weight: 700;
                            margin: 1rem 0;
                        }
                        table{
                            tbody{
                                tr{
                                    &:last-child{
                                        th{
                                            border-bottom: none;
                                        }
                                        td{
                                            border-bottom: none;
                                        }
                                    }
                                    th{
                                        border-left: none;
                                        border-right: none;
                                        font-weight: 700;
                                        border: 1px solid #e2e2e2;
                                        border-left: 0;
                                        border-right: 0;
                                    }
                                    td{
                                        border-left: none;
                                        border-right: none;
                                        border:1px solid #e2e2e2;
                                        border-left: 0;
                                        border-right: 0;
                                    }
                                }
                            }
                        }
                        .woocommerce-Reviews{
                            .full-details-inner{
                                .reveiw-form{
                                    padding: 60px 40px;
                                    background: #F6F6F6;
                                    .heading-title{
                                        font-size: 24px;
                                        font-weight: 400;
                                        margin: 1rem 0;
                                    }
                                    .contact-form{
                                        .input-box{
                                            width: 100%;
                                            textarea{
                                                width: 100%;
                                                padding: 0.5em 1em 0.5em 1em;
                                                background: #fff !important;
                                                border: 1px solid transparent;
                                                transition: all .4s;
                                                background: unset;
                                                margin-bottom: 30px;
                                                @media(max-width:991px){
                                                    margin-bottom: 25px;
                                                }
                                                &:hover{
                                                    border: 1px solid var(--color-primary);
                                                }
                                                &:focus{
                                                    outline: 0;
                                                    border-color: var(--color-primary);
                                                    color: var(--color-primary);
                                                }
                                                &::placeholder{
                                                    color: #000000;
                                                }
                                            }
                                            input{
                                                width: 100%;
                                                padding: 0.5em 1em 0.5em 1em;
                                                background: #fff !important;
                                                height: 55px;
                                                margin-bottom: 25px;
                                                border: 1px solid transparent;
                                                border: 1px solid transparent;
                                                margin-bottom: 25px;
                                                transition: all .4s;
                                                background: unset;
                                                &:hover{
                                                    border: 1px solid var(--color-primary);
                                                }
                                                &:focus{
                                                    outline: 0;
                                                    border-color: var(--color-primary);
                                                    box-shadow: 0 0 0 4px var(--color-shadow);
                                                    background-color: var(--color-bg);
                                                    color: var(--color-contrast-higher);
                                                }
                                                &::placeholder{
                                                    color: var(--color-primary);
                                                }
                                            }
                                        }
                                        .rating{
                                            display: flex;
                                            align-items: center;
                                            margin-bottom: 25px;
                                            p{
                                                margin-bottom: 0;
                                            }
                                        }
                                        .btn{
                                            width: 100%;
                                            max-width: fit-content;
                                            border: none;
                                            display: block;
                                            height: auto;
                                            border-radius: 0;
                                            color: #fff;
                                            border: 1px solid var(--color-primary);
                                            &:hover{
                                                color: #111111;
                                                border: 1px solid #e2e2e2;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ms-single-product .ms-single-product__content {
    padding-left: 120px;
    position: relative;
    .posted_in{
        strong{
            color: #111111;
            font-size: 24px;
            font-weight: 400;
            font-family: "Arapey", sans-serif;
        }
        span{
            color: #777777;
        }
    }
    .product_meta{
        margin-bottom: 20px;
    }
    .product-making{
        ul{
            padding: 10px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            li{
                list-style: none;
                width: 50%;
                margin: 0 0 10px 0;
                @media(max-width:450px){
                    width: 100%;
                }
                span{
                    color: #777777;
                }
            }
        }
    }
}
.ms-single-product .ms-single-product__content .ms-single-product_title {
    font-size: 28px;
    margin-bottom: 2rem;
    font-weight: 400;
}
.ms-single-product .ms-single-product__content .price {
    margin: 2rem 0;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: baseline;
    font-size: 36px;
}
.ms-single-product .ms-single-product__content .price bdi {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-primary);
}
.ms-single-product .ms-single-product__content .ms-woocommerce-product-rating {
    display: flex;
    align-items: center;
    line-height: initial;
    margin-bottom: 2rem;
}
.ms-single-product .ms-rating-icon {
    display: flex;
    align-items: center;
    .person-rating{
        margin-left: 10px;
    }
}
.ms-single-product .ms-rating-icon svg {
    fill: #FFB03B;
    width: 20px;
}
.ms-single-product .ms-single-product__content .stock, .ms-single-product .ms-single-product__content .woocommerce-product-details__short-description {
    margin-bottom: 4rem;
    line-height: 1.8;
}
.ms-single-product .ms-single-product__content .stock.in-stock {
    color: #2eb740;
}
.ms-single-product .ms-single-product__content .quantity-area {
    display: flex;
    align-items: center;
    margin-bottom: 55px;
    .cart-edit{
        margin: 0 20px 0 0;
        button{
            padding: 18px 0;
            border: none;
            @media(max-width:576px){
                padding: 14px 0;
            }
        }
        input{
            border: none;
        }
    }
    .rts-btn{
        border: 1px solid var(--color-primary);
        &:hover{
            background: none;
            border: 1px solid #e2e2e2;
            color: #040404;
        }
    }
}
.ms-single-product .ms-single-product__content .cart {
    display: flex;
}
.related{
    h2{
        font-size: 50px;
        font-weight: 400;
    }
    .blog-single-two-wrapper{
        transition: all .4s;
        &:hover{
            .inner{
                .pre-tag{
                    opacity: 0;
                    transform: translateY(-30px);
                }
                .btn-read-more-blog{
                    opacity: 1;
                    transform: translateY(-25px);
                }
            }
        }
        .image-area{
            img{
                width: 100%;
            }
        }
        .inner{
            text-align: center;
            margin-top: 20px;
            .title{
                margin-bottom: 10px;
                font-size: 18px !important;
                font-weight: 500;
                color: #111111;
                font-family: "Plus Jakarta Sans", sans-serif !important;
            }
            .pre-tag{
                display: block;
                color: var(--color-primary);
                font-weight: 600;
                transition: all .4s;
            }
            .btn-read-more-blog{
                opacity: 0;
                transform: translateY(30px);
                transition: all .4s;
                display: block;
                background: var(--color-primary);
                color: #fff;
                width: fit-content;
                margin: 0 auto;
                padding: 5px 15px;
                font-size: 14px; 
                font-weight: 500;
            }
        }
    }
}