// blog style here

.rts-blog-area{
    background: url(../images/blog/bg-01.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .banner-one-wrapper{
        text-align: center;
        margin-bottom: 55px;
        position: relative;
        overflow: visible;
        .title-banner{
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 50px;
            line-height: 58px;
            line-height: 1.2;
            color: #FFFFFF;
            font-family: var(--font-primary);
            animation: fadeInUp2 1s;
            transition-delay: .5s;
            @media #{$smlg-device} {
                font-size: 49px;
                line-height: 70px;
            }
            @media #{$md-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 45px;
            }
        }
        .desc{
            transition-delay: .8s;
            @media(max-width:500px){
                br{
                    display: none;
                }
            }
        }
    }
    .swiper-technical-main-wrapper{
        overflow: hidden;
        .mySwiper-blog{
            overflow: hidden;
            .swiper-slide{
                overflow: hidden;
            }
            .blog-wrapper{
                position: relative;
                transition: all .5s;
                overflow: hidden;
                width: 100%;
                &:hover{
                    .image-part{
                        img{
                            transform: scale(110%);
                        }
                    }
                    .content{
                        .title{
                            a{
                                color: var(--color-primary);
                            }
                        }
                    }
                }
                .image-part{
                    width: 100%;
                    transition: all .5s;
                    overflow: hidden;
                    position: relative;
                    &::before{
                        content: "";
                        width: 100%;
                        height: 100%;
                        display: block;
                        background: linear-gradient(360deg, #0e0e0e -6%, rgba(17, 17, 17, 0) 125%);
                        opacity: 1;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                    img{
                        width: 100%;
                        transform: scale(100%);
                        transition: all .5s;
                    }
                }
                .blog-badge{
                    color: #FFFFFF;
                    background: var(--color-primary);
                    display: inline;
                    padding: 7px 15px;
                    font-size: 14px;
                    font-weight: 700;
                    position: absolute;
                    top: 0;
                    left: 50px;
                    z-index: 1;
                }
                .content{
                    padding: 15px 35px 0px 8px;
                    position: absolute;
                    bottom: 22px;
                    left: 40px;
                    z-index: 1;
                    .tag{
                        margin-bottom: 10px;
                        a{
                            color: var(--color-primary);
                            font-size: 14px;
                            font-weight: 700;
                            text-transform: uppercase;
                            pointer-events: none;
                        }
                    }
                    .title{
                        transition: all .5s;
                        a{
                            color: #FFFFFF;
                            font-size: 24px;
                            font-weight: 400;
                            line-height: 33px;
                            display: inline-block;
                            transition: all .5s;
                        }
                    }
                }
            }
        }
    }
    &.rts-blog-area-2{
        background: none;
        padding-bottom: 180px;
        .banner-one-wrapper{
            .title-banner{
                font-size: 50px;
                line-height: 58px;
                margin: 0px 0px 8px 0px;
                color: #111111;
                transition-delay: .3s;
                animation: slideInUp2 1s;
            }
        }
        .swiper-technical-main-wrapper{
            .mySwiper-blog{
                .blog-wrapper{
                    &:hover{
                        .image-part{
                            &::after{
                                display: none;
                            }
                        }
                        .content{
                            .title{
                                a{
                                    color: var(--color-primary);
                                }
                            }
                        }
                    }
                    .image-part{
                        &::before{
                            display: none;
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 90%;
                            height: 90%;
                            border: 2px solid #FFFFFF;
                            right: 20px;
                            top: 20px;
                            transition: all .4s;
                        }
                    }
                    .blog-badge{
                        display: grid;
                        text-align: center;
                        right: 20px;
                        left: unset;
                        top: 20px;
                        background: #DD5903;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        width: 85px;
                        height: 85px;
                        text-transform: uppercase;
                        padding: 3px;
                        line-height: 16px;
                        .date{
                            font-size: 50px;
                            line-height: 50px;
                            font-weight: 400;
                        }
                        .month{
                            font-weight: 700;
                        }
                    }
                    .content{
                        position: unset;
                        padding-top: 40px;
                        padding-right: 0;
                        .title{
                            a{
                                color: #111111;
                                font-size: 30px;
                                font-weight: 400;
                                line-height: 34px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.home-three{
        padding-bottom: 120px;
        .banner-one-wrapper{
            .banner-shape-area{
                text-align: center;
                justify-content: center;
            }
        }
        .rts-blog-inner{
            .blog-wrapper{
                position: relative;
                transition: all .3s;
                 &:hover{
                     .image-part{
                        img{
                            transform: scale(120%);
                        }
                         &::after{
                             display: none;
                         }
                     }
                     .content{
                         .title{
                             a{
                                 color: var(--color-primary);
                             }
                         }
                     }
                }
                .image-part{
                    position: relative;
                    transition: all .5s;
                    overflow: hidden;
                    img{
                        width: 100%;
                        transform: scale(100%);
                        transition: all .5s;
                    }
                    &::before{
                        display: none;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 90%;
                        height: 90%;
                        border: 2px solid #FFFFFF;
                        right: 20px;
                        top: 20px;
                        transition: all .4s;
                    }
                }
                .blog-badge{
                    display: grid;
                    text-align: center;
                    position: absolute;
                    right: 20px;
                    left: unset;
                    top: 20px;
                    background: #DD5903;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    width: 85px;
                    height: 85px;
                    text-transform: uppercase;
                    padding: 3px;
                    line-height: 16px;
                    .date{
                        font-size: 50px;
                        line-height: 50px;
                        font-weight: 400;
                    }
                    .month{
                        font-weight: 700;
                    }
                }
                .content{
                    position: unset;
                    padding-top: 40px;
                    padding-right: 0;
                    h3.title{
                        line-height: 1;
                        a{
                            color: #111111;
                            font-size: 30px;
                            font-weight: 400;
                            line-height: 34px;
                        }
                    }
                    .tag{
                        margin-bottom: 10px;
                        a{
                            color: var(--color-primary);
                            font-size: 14px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                    }
                }
                
            }
        }
    }
    &.home-seven{
        background: none;
        .banner-one-wrapper{
            .title-banner{
                color: #111111;
            }
        }
        .swiper-technical-main-wrapper{
            transition-delay: 1.2s;
        }
    }
}
.home-four{
    .rts-blog-area.rts-blog-area-2{
        padding-bottom: 120px;
    }
}
.home-five{
    .rts-blog-area.rts-blog-area-2{
        padding-bottom: 120px;
    }
}
.rts-single-wized{
    background: transparent;
    border-radius: 0;
    padding: 40px;
    margin-bottom: 40px;
    border: 1px solid #E9E9E9;
    &:last-child{
        margin-bottom: 0;
    }
    @media #{$small-mobile} {
        padding: 20px;
    }
    &.service{
        border-radius: 0;
        .single-categories{
            li{
                a{
                    border-radius: 0;
                }
            }
        }
    }
    &.download{
        background: #171717;
        .title{
            color: #fff;
        }
        .single-download-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #2D2D2D;
            &:first-child{ 
                padding-top: 0;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
            .mid{
                margin-right: auto;
                margin-left: 15px;
                .title{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-family: var(--font-primary);
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }
            a{
                &.rts-btn{
                    padding: 11px 15px;
                    border-radius: 0;
                }
            }
        }
    }
    &.contact{
        background: #1C2539;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        @media #{$small-mobile} {
            padding: 25px 20px;
        }
        &:last-child{
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
        }
        .wized-body{
            text-align: center;
            .title{
                color: #fff;
                margin-bottom: 30px;
                font-size: 22px;
                line-height: 32px;
            }
            a{
                &.rts-btn{
                    display: block;
                    max-width: max-content;
                    margin: auto;
                }
            }
        }
    }
    .wized-header{
        .title{
            margin-bottom: 10px;
        }
    }
    .wized-body{
        margin-top: 30px;
        .rts-search-wrapper{
            position: relative;
            input{
                background: transparent;
                height: 55px;
                border-radius: 0;
                padding-right: 70px;
                padding-left: 25px;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                position: absolute;
                max-width: max-content;
                height: 55px;
                width: 55px;
                border-radius: 0;
                background: var(--color-primary);
                display: inline-block;
                padding: 0 19px;
                right: 0;
                i{
                    color: #fff;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }
    .single-categories{
        margin-bottom: 15px;
        padding: 0;
        &:last-child{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 25px;
                background: transparent;
                color: #5D666F;
                font-weight: 500;     
                transition: .3s;  
                border-radius: 0;
                border: 1px solid #E9E9E9;      
                i{
                    color: var(--color-primary);
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    transform: translateY(-5px) scale(1.03);
                    color: var(--color-white);  
                    i{
                        color: #fff;
                    }  
                }
            }
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 0;
            img{
                min-width: 85px;
                height: auto;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    // gallery post
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 0;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 97px;
                    height: auto;
                    transition: .6s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: 269px;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 16px;
            background: transparent;
            border-radius: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .5s;
            border: 1px solid #E9E9E9;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
                border: 1px solid transparent;
            }
        }
    }
}
.blog-single-post-listing{
    border: 1px solid #E6E9F0;
    margin-bottom: 50px;
    @media #{$smlg-device} {
        margin-right: 0;
    }
    // details style hear...
    &.details{
        border-radius: 0;
        .thumbnail{
            border-radius: 0;
            &.details{
                border-radius: 0;
                width: 100%;
                max-width: max-content;
                @media #{$smlg-device} {
                    max-width: 100%;
                }
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }
        .rts-quote-area{
            padding: 120px 50px 50px;
            background: var(--color-primary);
            border-radius: 0;
            margin-bottom: 40px;
            position: relative;
            @media #{$small-mobile} {
                margin-top: 15px;
                padding: 120px 30px 50px;
            }
            &::before{
                position: absolute;
                left: 50%;
                top: 40px;
                transform: translate(-50%, 0);
                content: "\f10d";
                font-family: "Font Awesome 6 Pro";
                text-align: center;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 50%;
                background: #fff;
                color: #dd5903;
                font-size: 22px;
            }
            .title{
                margin-bottom: 25px;
                color: #FFFFFF;
                font-size: 28px;
                line-height: 1.3;
                font-weight: 400;
                font-family: "Arapey", sans-serif;
                margin-bottom: 15px;
                @media #{$small-mobile} {
                    font-size: 18px;
                    margin-bottom: 15px;
                }
            }
            .name{
                color: #ffffff;
                font-size: 28px;
                line-height: 1.3;
                font-weight: 400;
                font-family: "Arapey", sans-serif;
                margin-bottom: 15px;
                @media #{$small-mobile} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #5D666F;
            }
        }
        .check-area-details{
            .single-check{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                i{
                    margin-right: 15px;
                    color: var(--color-primary);
                    @media #{$small-mobile} {
                    margin-top: -26px;
                    }
                }
                span{
                    color: #5D666F;
                }
            }
        }
        .details-tag{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media #{$small-mobile} {
                justify-content: flex-start;
            }
            h6{
                margin-bottom: 0;
                font-size: 18px;
                margin-right: 15px;
            }
            button{
                padding: 8px 12px;
                background: #F6F6F6;
                max-width: max-content;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 0;
                color: #1C2539;
                transition: .3s;
                &:last-child{
                    @media #{$laptop-device} {
                        margin-top: 10px;
                        margin-left: -2px;
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: translateY(-2px) scale(1.02);
                }
            }
        }
        .details-share{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media #{$md-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$sm-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                justify-content: flex-start;
                margin-top: 20px;
            }
            button{
                max-width: max-content;
                position: relative;
                z-index: 1;
                margin-left: 23px;
                color: #1C2539;
                transition: .3s;
                font-size: 14px;
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    color: #fff;
                    transform: scale(1.2);
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
            h6{
                font-size: 18px;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .author-area{
            margin-top: 44px;
            display: flex;
            align-items: center;
            padding: 40px;
            background: #f7f7f7;
            @media #{$sm-layout} {
                align-items: flex-start;
            }
            @media #{$large-mobile} {
                flex-wrap: wrap;
            }
            .thumbnail{
                margin-right: 30px;
                width: 50%;
                img{
                    border-radius: 15px;
                }
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
            .author-details{
                @media #{$sm-layout} {
                    margin-left: 15px;
                }
                h5{
                    margin-bottom: 10px;
                    font-weight: 400;
                }
                p{
                    line-height: 26px;
                }
            }
        }
    }

    .replay-area-details{
        margin-top: 40px;
        form{
            input{
                height: 55px;
                border-radius: 0;
                background: transparent;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                border-radius: 0;
                background: transparent;
                height: 140px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid #E9E9E9;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                border: 1px solid var(--color-primary);
                &:hover{
                    color: #111111;
                    border: 1px solid #E9E9E9;
                }
            }
        }
    }


    .thumbnail{
        overflow: hidden;
        img{
            transition: .8s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .blog-listing-content{
        padding: 50px;
        @media #{$sm-layout} {
            padding: 25px 10px;
        }
        .user-info{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            flex-wrap: wrap;
            .single{
                margin-right: 30px;
                min-width: max-content;
                @media #{$sm-layout} {
                    margin-right: 5px;
                }
                @media #{$large-mobile} {
                    margin-right: 5px;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                    @media #{$large-mobile} {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                }
                span{
                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }
        }
        .blog-title{
            transition: .3s;
            .title{
                transition: .3s;
                margin-bottom: 16px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                // margin-bottom: 32px;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 35px;
                display: block;
                max-width: max-content;
                border: 1px solid var(--color-primary);
                &:hover{
                    border: 1px solid #e2e2e2;
                    color: #111111;
                }
                @media #{$small-mobile} {
                    margin-top: 20px;
                }
            }
        }
    }
}