// cta style hear
.rts-cta-area{
    width: 100%;
    background: url(../images/cta/cta-bg.webp);
    background-color: #111111;
    padding: 37px 40px 35px 60px;
    @media(max-width:576px){
        padding: 30px 10px;
    }
    .cta-inner{
        display: flex;
        justify-content: space-between;
        @media(max-width:991px){
            display: block;
            text-align: center;
        }
        .title{
            color: #FFFFFF;
            margin-bottom: 0;
            font-size: 40px;
            font-weight: 400;
            line-height: 40px;
            @media(max-width:991px){
                margin-bottom: 20px;
            }
            @media(max-width:768px){
                font-size: 36px;
                line-height: 46px;
            }
            @media(max-width:500px){
                font-size: 30px;
                line-height: 40px;
            }
            .second-title{
                font-size: 40px;
                line-height: 40px;
                color: #FFFFFF;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 5px;
                @media(max-width:768px){
                    font-size: 36px;
                    line-height: 46px;
                }
                @media(max-width:500px){
                    font-size: 30px;
                    line-height: 40px;
                }
            }
        }
        .rts-btn{
            @media(max-width:991px){
                margin: 0 auto;
            }
            i{
                margin-right: 10px;
            }
            &:hover{
                border-color: #FFFFFF;
            }
        }
    }
}
.home-one.about{
    .rts-cta-area{
        background: var(--color-primary);
        .rts-btn{
            background: #FFFFFF;
            color: #111111;
            border: 1px solid #FFFFFF;
            i{
                margin-right: 10px;
            }
            &:hover{
                background: var(--color-primary);
                color: #FFFFFF;
            }
        }
    }
}