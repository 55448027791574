// button style hear

.rts-btn{
    padding: 20px 33px;
    font-family: var(--font-secondary);
    line-height: 10px;
    max-width: max-content;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: .3s;
    display: block;
    min-width: max-content;
    @media(max-width:576px){
        padding: 15px 25px;
    }
    &.btn-primary{
        background: var(--color-primary);
        color: #fff;
        border: 2px solid var(--color-primary);
        position: relative;
        overflow: hidden;
        &:hover{
            border: 2px solid rgba(27, 27, 27, 1);
            background: none;
        }
    }
    &.btn-seconday{
        background: none;
        border: 2px solid rgba(27, 27, 27, 1);
        color: #fff;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:hover{
            border: 2px solid var(--color-primary);
            background: var(--color-primary);
        }
    }
    &.btn-terciary{
        background: #FFFFFF;
        color: #0C0A0A;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:hover{
            color: #fff;
            &::after{
                transform: translateY(0);
            }
        }
    }
}

.btn-read-more{
    color: #FF6600;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    font-family: var(--font-primary);
    i{
        margin-left: 5px;
    }
}

// button area start
.vedio-icone{
    .video-play-button {
        position: absolute;
        z-index: 2;
        top: 35%;
        left: 15%;
        transform: translateX(-50%) translateY(-50%);
        box-sizing: content-box;
        display: block;
        width: 32px;
        height: 44px;
        border-radius: 50%;
        padding: 18px 20px 18px 28px;
        display: flex;
        &::before{
            content: "";
            position: absolute;
            z-index: 0;
            left: -32%;
            top: -31%;
            display: block;
            width: 130px;
            height: 130px;
            background: transparent;
            border-radius: 50%;
            border: 1px solid var(--color-primary);
            animation: waves 3s ease-in-out infinite;
        }
        &::after{
            content: "";
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #ffffff;
            transition: all 200ms;
            @media #{$large-mobile} {
                width: 100px;
                height: 100px;
            }
        }
        span{
            display: block;
            position: relative;
            z-index: 3;
            width: 0;
            height: 0;
            border-left: 18px solid var(--color-primary);
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 47%;
            &.outer-text{
                border: none;
                min-width: max-content;
                margin-left: 75px;
                position: relative;
                margin-top: -12px;
                color: var(--color-primary);
                font-weight: 500;
            }
        }
    }
    .video-overlay {
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        opacity: 0;
        transition: all ease 500ms;
        display: none;
        iframe{
            width: 70%;
            height: 70%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
        }
        &.open{
            position: fixed;
            z-index: 1000;
            opacity: 1;
            display: block;
        }
        // .video-overlay-close {
        //     position: absolute;
        //     z-index: 1000;
        //     top: 200px;
        //     right: 300px;
        //     font-size: 36px;
        //     line-height: 1;
        //     font-weight: 400;
        //     color: #fff;
        //     text-decoration: none;
        //     cursor: pointer;
        //     transition: all 200ms;
        // }
        .video-overlay-close {
            position: absolute;
            z-index: 1000;
            top: 15px;
            right: 20px;
            font-size: 36px;
            line-height: 1;
            font-weight: 400;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            transition: all 200ms;
        }
    }
}