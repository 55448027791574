// banner one style hear
.rts-banner-one{
    position: relative;
    z-index: 1;
    overflow: hidden;
    @media(max-width:575px){
        padding: 80px 0 !important;
        background-position: bottom !important;
    }
    &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: rgba(0, 0, 0, 0.3);
    }

    .banner-one-wrapper{
        text-align: center;
        @media(max-width:575px){
            padding: 0 !important;
        }
        span.b_one-pre{
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            font-family: var(--font-secondary);
            color: var(--color-white);
            text-transform: uppercase;
            opacity: 0;
            @media(max-width:575px){
                font-size: 11px;
            }
        }
        .banner-shape-area{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 10px;
            opacity: 0;
            @media(max-width:575px){
                margin-top: 25px;
            }
            .shape{
                height: 10px;
                width: 10px;
                line-height: 10px;
                transform: rotate(45deg);
                margin: 0 6px;
                background: var(--color-primary);
                display: block;
            }
        }
        .title-banner{
            margin-top: 20px;
            font-weight: 400;
            font-size: 80px;
            line-height: 1.2;
            color: #fff;
            margin-bottom: 50px;
            font-family: var(--font-primary);
            opacity: 0;
            @media #{$smlg-device} {
                font-size: 49px;
                line-height: 70px;
            }
            @media #{$md-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 45px;
            }
        }
        .button-area-banner{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            transition-delay: 1.5s;
            opacity: 0;
            @media #{$large-mobile} {
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
            }
            .rts-btn{
                @media(max-width:768px){
                    padding: 15px 20px;
                }
            }
        }
        .call-area{
            display: flex;
            align-items: center;
            .icon{
                margin-right: 13px;
                i{
                    width: 52px;
                    height: 52px;
                    background: #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(-35deg);
                    color: var(--color-primary);
                    font-weight: 600;
                }
            }
            .detail{
                span{
                    display: block;
                    text-transform: capitalize;
                }
                a.number{
                    font-weight: 700;
                    font-size: 24px;
                    color: #fff;
                    line-height: 37px;
                    transition: .3s;
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}


.about-one-right-wrapper{
    @media #{$laptop-device} {
        background-color: transparent !important;
    }
    @media #{$smlg-device} {
        padding: 30px;
    }
    @media #{$smlg-device} {
        background-color: transparent !important;
    }
}




.rts-banner-main-area-swiper{
    position: relative;
    overflow: hidden;
    margin: 0;
    background-color: #1F1F1F;
    .banner-area-sidebar{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 0;
        position: absolute;
        background: rgba(41, 41, 41, 1);
        width: 100%;
        height: 60px;
        top: 50%;
        right: -48.5%;
        z-index: 10;
        transform: rotate(-90deg);
        @media(max-width:700px){
            display: none;
        }
        .mail{
            a{
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                margin-right: 20px;
                text-transform: uppercase;
            }
        }
        .content{
            display: flex;
            align-items: center;
            .open{
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;  
                margin-right: 5px;
                color: #666666;
            }
            .time{
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .swiper-pagination {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 0;
        right: auto !important;
        width: 150px !important;
        margin: 0;
        display: grid;
        align-items: center;
        justify-content: center;
        @media(max-width:768px){
            left: -25px;
        }
        @media #{$large-mobile} {
            display: none;
        }
    }
    .swiper-pagination-bullet {
        padding: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 4px;
        font-weight: 600;
        font-size: 14px;
        opacity: 1;
        text-transform: uppercase;
        background: url(../images/bg/bg-image-2.webp);
        background-position: center;
        background-size: cover;
        letter-spacing: 0.05em;
        margin: 0 !important;
        position: relative;
        margin: 7px 0 !important;
        &::before{
            content: '';
            position: absolute;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: #FFFFFF;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
        &:first-child{
            background: url(../images/bg/bg-image-1.webp);
            background-position: center;
            background-size: cover;
        }
        &:last-child{
            background: url(../images/bg/bg-image-3.webp);
            background-position: center;
            background-size: cover;
        }
        &.swiper-pagination-bullet-active{
            position: relative;
            &::after{
                display: none;
            }
            &::before{
                color: var(--color-primary);
            }
        }
    }
    .swiper-pagination-bullet-active {
        color:#000;
        border: 2px solid #FFFFFF;
        @media #{$smlg-device} {
            color: var(--color-primary);
        }
    }
    .swiper-slide.swiper-slide-active{
        .banner-one-wrapper{
            opacity: 1;
            span.b_one-pre{
                animation: fadeInUp 1s;
                animation-delay: .5s;
                opacity: 1;
            }
            .banner-shape-area{
                animation: slideInUp3 1s;
                animation-delay: 1s;
                opacity: 1;
            }
            .title-banner{
                animation: fadeInUp 1s;
                animation-delay: 1.2s;
                opacity: 1;
            }
            .button-area-banner{
                animation: fadeInUp 1s;
                animation-delay: 1.5s;
                opacity: 1;
            }
        }
    }
    &.home-seven{
        .banner-social-area{
            position: absolute;
            top: 50%;
            left: -80px;
            transform: translateY(50%);
            z-index: 2;
            @media(max-width:1400px){
                left: -140px;
            }
            @media(max-width:1200px){
                display: none;
            }
            .inner{
                display: flex;
                align-items: center;
                transform: rotate(-90deg);
                .content{
                    margin-bottom: 0;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    margin-left: 15px;
                    span{
                        margin-right: 5px;
                    }
                }
                .divider{
                    width: 100px;
                    height: 1px;
                    margin: auto 0;
                    background: #FFFFFF;
                    margin-left: 5px;

                }
                .social-wrapper{
                    display: flex;
                    padding: 0;
                    li{
                        list-style: none;
                        margin: 0 15px;
                        i{
                            color: #FFFFFF;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        .swiper-container-h2{
            .swiper-wrapper{
                .rts-banner-one{
                    padding: 160px 0 65px 0;
                    .container{
                        @media (max-width:1500px) and (min-width:1200px){
                            max-width: 1024px;
                        }
                    }
                    &.bg_image--1{
                        background: url(../images/bg/bg-image-7.jpg);
                        background-size: cover;
                    }
                    &.bg_image--2{
                        background: url(../images/bg/bg-image-8.jpg);
                        background-size: cover;
                    }
                    
                    .banner-one-wrapper{
                        .b_one-pre{
                            color: var(--color-primary);
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 37px;
                            font-family: var(--font-primary);
                            @media(max-width:991px){
                                font-size: 16px;
                                line-height: 1.2;
                            }
                        }
                        .desc{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 30px;
                            color: #FFFFFF;
                            margin-bottom: 55px;
                            @media(max-width:768px){
                                br{
                                    display: none;
                                }
                            }
                        }
                        .button-group-area{
                            display: flex;
                            align-items: center;
                            gap: 30px;
                            @media(max-width:576px){
                                display: block;
                            }
                            .rts-btn{
                                background: transparent;
                                border: 1px solid #FFFFFF;
                                display: flex;
                                align-items: center;
                                padding: 23px 30px;
                                @media(max-width:576px){
                                    margin-bottom: 20px;
                                }
                                &.p-short{
                                    padding: 8px 8px 8px 25px;
                                }
                                i{
                                    height: 40px;
                                    width: 40px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #fff;
                                    color: var(--color-primary);
                                    margin-left: 20px;
                                }
                                &:hover{
                                    border: 1px solid var(--color-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
        .swiper-pagination {
            position: absolute;
            bottom: 100px;
            right: 30px;
            left: auto !important;
            width: 300px !important;
            margin: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            @media(max-width:1200px){
                right: 50% !important;
                transform: translateX(50%);
                bottom: 40px;
            }
            @media #{$large-mobile} {
                display: none;
            }
        }
        .swiper-pagination-bullet {
            padding: 0;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            text-align: center;
            line-height: 4px;
            font-weight: 600;
            font-size: 14px;
            border: 2px solid #FFFFFF;
            opacity: 1;
            text-transform: uppercase;
            background: none;
            letter-spacing: 0.05em;
            margin: 0 !important;
            position: relative;
            margin: 0 7px !important;
            @media(max-width:1200px){
                width: 40px;
                height: 40px;
                padding: 25px !important;
            }
            &::before{
                content: '02';
                position: absolute;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                color: #FFFFFF;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
            &:first-child{
                &::before{
                    content: '01';
                }
            }
            &:last-child{
                &::before{
                    content: '03';
                }
            }
            @media #{$smlg-device} {
                padding: 30px;
            }
            @media #{$sm-layout} {
                padding: 20px 10px;
                font-size: 13px;
            }
            &.swiper-pagination-bullet-active{
                position: relative;
                width: 67px;
                height: 67px;
                @media(max-width:1200px){
                    width: 50px;
                    height: 50px;
                }
                &::after{
                    display: none;
                }
                &::before{
                    color: var(--color-primary);
                }
            }
        }
        .swiper-pagination-bullet-active {
            color:#000;
            background: #fff;
            @media #{$smlg-device} {
                color: var(--color-primary);
            }
        }
        .swiper-slide.swiper-slide-active{

            .banner-one-wrapper{
                opacity: 1;
                span.b_one-pre{
                    animation: slideInUp3 1s ;
                    animation-delay: .5s;
                    display: block;
                    opacity: 1;
                }
                .title-banner{
                    animation: fadeInUp2 1s ;
                    animation-delay: 1s;
                    opacity: 1;
                }
                .button-area-banner{
                    animation: fadeInUp2 1.5s ;
                    animation-delay: 2s;
                    opacity: 1;
                }
            }
        }
    }
    &.banner-one{
        .banner-one-wrapper{
            text-align: center;
            span.b_one-pre{
                letter-spacing: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                font-family: var(--font-secondary);
                color: var(--color-white);
                text-transform: uppercase;
                transition-delay: .5s;
                display: block;
                animation: none;
            }
            .banner-shape-area{
                display: flex;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 10px;
                transition-delay: 1s;
                animation: none;
                .shape{
                    height: 10px;
                    width: 10px;
                    line-height: 10px;
                    transform: rotate(45deg);
                    margin: 0 6px;
                    background: var(--color-primary);
                    display: block;
                }
            }
            .title-banner{
                margin-top: 20px;
                font-weight: 400;
                margin-left: 0;
                margin-right: 0;
                font-size: 80px;
                line-height: 1.2;
                margin-bottom: 50px;
                font-family: var(--font-primary);
                transition-delay: 1.2s;
                animation: none;
                @media #{$smlg-device} {
                    font-size: 49px;
                    line-height: 70px;
                }
                @media #{$md-layout} {
                    font-size: 42px;
                    line-height: 56px;
                }
                @media #{$sm-layout} {
                    font-size: 42px;
                    line-height: 56px;
                }
                @media #{$large-mobile} {
                    font-size: 30px;
                    line-height: 45px;
                }
            }
            .button-area-banner{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;
                transition-delay: 1.5s;
                opacity: 0;
                animation: none;
                @media #{$large-mobile} {
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 15px;
                }
                .rts-btn.btn-seconday{
                    border-color: rgb(54, 54, 54);
                    &:hover{
                        border-color: var(--color-primary);
                    }
                }
                .rts-btn.btn-primary:hover{
                    border-color: rgb(54, 54, 54);
                }
            }
        }
    }
    &.banner-two{
        background: #1F1F1F;
        padding: 200px 0 150px 0;
        position: relative;
        z-index: 1;
        .banner-shape-1{
            position: absolute;
            top: -13%;
            left: -22%;
            z-index: -1;
        }
        .banner-shape-2{
            position: absolute;
            right: -26%;
            bottom: -26%;
            z-index: -1;
            width: 100%;
            animation: taadaa 3s linear infinite;
            img{
                width: 100%;
            }
        }
        .first{
            @media(max-width:768px){
                order: 2;
            }
        }
        .banner-one-wrapper{
            text-align: start;
            @media(max-width:768px){
                text-align: center;
            }
            span.b_one-pre{
                letter-spacing: 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                font-family: var(--font-secondary);
                color: var(--color-white);
                text-transform: uppercase;
                transition-delay: .3s;
                display: block;
            }
            .banner-shape-area{
                display: flex;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 10px;
                transition-delay: .5s;
                @media(max-width:768px){
                    justify-content: center;
                }
                .shape{
                    height: 10px;
                    width: 10px;
                    line-height: 10px;
                    transform: rotate(45deg);
                    margin: 0 6px;
                    background: var(--color-primary);
                    display: block;
                }
            }
            .title-banner{
                margin-top: 20px;
                font-weight: 400;
                margin-left: 0;
                margin-right: 0;
                font-size: 80px;
                line-height: 1.2;
                color: #fff;
                margin-bottom: 50px;
                font-family: var(--font-primary);
                transition-delay: .7s;
                @media #{$smlg-device} {
                    font-size: 49px;
                    line-height: 70px;
                }
                @media #{$md-layout} {
                    font-size: 42px;
                    line-height: 56px;
                }
                @media #{$sm-layout} {
                    font-size: 42px;
                    line-height: 56px;
                }
                @media #{$large-mobile} {
                    font-size: 30px;
                    line-height: 45px;
                }
            }
            .button-area-banner{
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 40px;
                transition-delay: 1s;
                @media(max-width:768px){
                    justify-content: center;
                    flex-direction: row;
                }
                @media(max-width:768px){
                    gap: 20px;
                }
                .rts-btn{
                    @media(max-width:576px){
                        padding: 15px 18px;
                        font-size: 12px;
                    }
                }
                .rts-btn.btn-seconday{
                    border-color: rgb(54, 54, 54);
                    &:hover{
                        border-color: var(--color-primary);
                    }
                }
                .rts-btn.btn-primary:hover{
                    border-color: rgb(54, 54, 54);
                }
            }
            .call-area{
                display: flex;
                align-items: center;
                .icon{
                    margin-right: 13px;
                    i{
                        width: 52px;
                        height: 52px;
                        background: #fff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: rotate(-35deg);
                        color: var(--color-primary);
                        font-weight: 600;
                    }
                }
                .detail{
                    span{
                        display: block;
                        text-transform: capitalize;
                    }
                    a.number{
                        font-weight: 700;
                        font-size: 24px;
                        color: #fff;
                        line-height: 37px;
                        transition: .3s;
                        &:hover{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
        .hero-section-area{
            position: relative;
            z-index: 1;
            @media(max-width:991px){
                text-align: center;
            }
            .shape-1{
                position: absolute;
                width: 500px;
                height: 500px;
                border-radius: 50%;
                border: 1px solid var(--color-primary);
                left: -40px;
                z-index: -1;
                @media(max-width:1200px){
                    width: 300px;
                    height: 300px;  
                }
                @media(max-width:991px){
                    left: -25px;  
                }
                @media(max-width:768px){
                    left: 90px;
                }
                @media(max-width:576px){
                    left: 30px;
                }
                @media(max-width:400px){
                    left: -7px;
                }
            }
            .shape-2{
                position: absolute;
                width: 500px;
                height: 500px;
                border-radius: 50%;
                border: 1px solid var(--color-primary);
                left: 25px;
                top: 40px;
                z-index: -1;
                @media(max-width:1200px){
                    width: 300px;
                    height: 300px;  
                }
                @media(max-width:991px){
                    left: -5px;  
                    top: 20px;
                }
                @media(max-width:768px){
                    left: 110px;  
                    top: 25px;
                }
                @media(max-width:576px){
                    left: 50px; 
                }
                @media(max-width:400px){
                    left: 5px;
                }
            }
            .shape-3{
                position: absolute;
                width: 500px;
                height: 500px;
                border-radius: 50%;
                border: 1px solid var(--color-primary);
                left: 40px;
                z-index: -1;
                @media(max-width:1200px){
                    width: 300px;
                    height: 300px;  
                }
                @media(max-width:991px){
                    left: 15px;  
                }
                @media(max-width:768px){
                    left: 125px;
                }
                @media(max-width:576px){
                    left: 70px;
                }
                @media(max-width:400px){
                    left: 25px;
                }
            }
            img{
                position: relative;
                animation: jump-2 7s linear infinite;
                &.main-img{
                    @media(max-width:1200px){
                        width: 300px;
                    }
                }
            }
        }
    }
    &.banner-three{
        background: #f5f5f5;
        padding: 150px 0 70px 0;
        position: relative;
        z-index: 1;
        .banner-shape-1{
            position: absolute;
            top: 45%;
            left: 0;
            transform: translateY(-50%);
            z-index: -1;
        }
        .banner-one-wrapper{
            text-align: start;
            position: relative;
            z-index: 3;
            .title-banner{
                z-index: 8;
                font-family: 'Arapey';
                text-transform: uppercase;
                height: auto;
                color: rgb(31, 31, 31);
                text-decoration: none;
                white-space: normal;
                width: 750px;
                min-height: 0px;
                min-width: 0px;
                max-height: none;
                max-width: none;
                text-align: left;
                line-height: 158px;
                letter-spacing: 0px;
                font-weight: 400;
                font-size: 160px;
                transform-origin: 50% 50%;
                opacity: 1;
                transform: translate(0px, 0px);
                visibility: visible;
                @media(max-width:991px){
                    font-size: 100px;
                    line-height: 1.1;
                }
                @media(max-width:576px){
                    font-size: 80px;
                    width: auto;
                }
                @media(max-width:450px){
                    font-size: 50px;
                }
            }
            .button-area-banner{
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 40px;
                transition-delay: 2s;
                @media #{$large-mobile} {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                .rts-btn.btn-seconday{
                    border-color: rgb(224, 224, 224);
                    color: #1F1F1F;
                    &:hover{
                        border-color: var(--color-primary);
                        color: #FFFFFF;
                    }
                }
                .rts-btn.btn-primary:hover{
                    border-color: rgb(224, 224, 224);
                    color: #1F1F1F;
                }
            }
        }
        .hero-section-area{
            position: absolute;
            z-index: 1;
            right: 10px;
            top: 40%;
            transform: translateY(-50%);
            @media(max-width:991px){
                top: 30%;
                right: 0;
            }
            @media(max-width:768px){
                top: 25%;
            }
            @media(max-width:576px){
                top: 20%;
                height: 450px;
            }
            img{
                position: relative;
                width: 100%;
                height: 100%;
            }
        }
        .rts-reservation-area{
            background: none;
            padding-top: 70px;
            form{
                gap: 20px;
                padding: 50px 0;
                .single-input{
                    h3.title{
                        color: #1F1F1F;
                        font-size: 24px;
                        text-transform: uppercase;
                        line-height: 24px;
                        font-weight: 400;
                    }
                    input{
                        border: none;
                        background: #FFFFFF;
                    }
                    .rts-btn{
                        &:hover{
                            color: #1F1F1F;
                            background: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }
    &.banner-four{
        background: url(../images/banner/bg-4.webp);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 200px 0 190px 0;
        position: relative;
        z-index: 1;
        @media(max-width:576px){
            padding: 150px 0 120px 0;
        }
        .banner-img-1{
            position: absolute;
            top: 0;
            left: 25px;
            @media(max-width:768px){
                left: 0;
            }
            @media(max-width:576px){
                width: 200px;
            }
        }
        .banner-img-2{
            position: absolute;
            top: 0;
            right: 0;
            @media(max-width:576px){
                width: 200px;
            }
        }
        .banner-img-3{
            position: absolute;
            top: 25%;
            right: 0;
            z-index: 1;
            @media(max-width:991px){
                z-index: -1;
            }
            @media(max-width:576px){
                width: 150px;
            }
        }
        .banner-img-4{
            position: absolute;
            bottom: 40px;
            left: 8%;
            animation: jump-2 10s linear infinite;
            @media(max-width:576px){
                width: 100px;
            }
        }
        .banner-left-image{
            overflow: hidden;
            @media(max-width:991px){
                margin-bottom: 40px;
            }
            @media(max-width:576px){
                width: 80%;
                margin: 0 auto 40px auto;
            }
        }
        .banner-right-image{
            width: 784px;
            height: 536px;
            position: relative;
            @media(max-width:991px){
                width: auto;
                height: auto;
                padding-left: 40px;
            }
            img{
                width: 100%;
            }
            .discount-tag{
                position: absolute;
                top: -20%;
                left: 15%;
                animation: jump-2 10s linear infinite;
                @media(max-width:991px){
                    top: 2%;
                    left: 0;
                }
                @media(max-width:576px){
                    width: 100px;
                }
            }
        }
    }
    &.banner-five{
        .rts-banner-one{
            .banner-one-wrapper{
                padding-top: 110px !important;
                span.b_one-pre{
                    transition-delay: .5s;
                }
                .banner-shape-area{
                    transition-delay: 1s;
                }
                .title-banner{
                    transition-delay: 1.2s;
                }
                .button-area-banner{
                    transition-delay: 1.5s;
                }
            }
        }
        .swiper-container{
            .swiper-wrapper{
                .rts-banner-one{
                    padding: 165px 0 155px 0;
                    &.bg_image--1{
                        background: url(../images/bg/bg-image-4.webp);
                        background-size: cover;
                    }
                    &.bg_image--2{
                        background: url(../images/bg/bg-image-5.webp);
                        background-size: cover;
                    }
                    &.bg_image--3{
                        background: url(../images/bg/bg-image-6.webp);
                        background-size: cover;
                    }
                }
                .banner-one-wrapper{
                    text-align: center;
                    .button-area-banner{
                        .rts-btn.btn-seconday{
                            border-color: #FFFFFF;
                            &:hover{
                                border-color: var(--color-primary);
                            }
                        }
                        .rts-btn.btn-primary:hover{
                            border-color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .swiper-pagination-bullet {
            background: url(../images/bg/bg-image-5.webp);
            background-position: center;
            background-size: cover;
            &:first-child{
                background: url(../images/bg/bg-image-4.webp);
                background-position: center;
                background-size: cover;
            }
            &:last-child{
                background: url(../images/bg/bg-image-6.webp);
                background-position: center;
                background-size: cover;
            }
            &.swiper-pagination-bullet-active{
                border-color: #c3c3c3;
            }
        }
    }
    &.banner-seven{
        background: url(../images/bg/pizza-banner.webp);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 170px 0 130px 0;
        position: relative;
        z-index: 1;
        animation: shrink 15s infinite alternate;
        @media(max-width:1400px){
            animation: none;
        }
        &::after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background: rgba(0, 0, 0, 0.56);
        }
        .rts-banner-one{
            position: relative;
            z-index: 1;
            overflow: hidden;
            &::after{
                display: none;
            }
        
            .banner-one-wrapper{
                text-align: center;
                span.b_one-pre{
                    letter-spacing: 0px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    font-family: var(--font-secondary);
                    color: var(--color-white);
                    text-transform: uppercase;
                    opacity: 1;
                    transition-delay: 1s;
                    display: block;
                    animation: fadeInUp 1.5s;
                }
                .banner-shape-area{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 40px;
                    margin-bottom: 10px;
                    opacity: 1;
                    transition-delay: 1s;
                    animation: fadeInUp 2s;
                    .shape{
                        height: 10px;
                        width: 10px;
                        line-height: 10px;
                        transform: rotate(45deg);
                        margin: 0 6px;
                        background: var(--color-primary);
                        display: block;
                    }
                }
                .title-banner{
                    margin-top: 20px;
                    font-weight: 400;
                    font-size: 80px;
                    line-height: 1.2;
                    color: #fff;
                    margin-bottom: 50px;
                    font-family: var(--font-primary);
                    opacity: 1;
                    transition-delay: 1s;
                    @media #{$smlg-device} {
                        font-size: 49px;
                        line-height: 70px;
                    }
                    @media #{$md-layout} {
                        font-size: 42px;
                        line-height: 56px;
                    }
                    @media #{$sm-layout} {
                        font-size: 42px;
                        line-height: 56px;
                    }
                    @media #{$large-mobile} {
                        font-size: 30px;
                        line-height: 45px;
                    }
                }
                .button-area-banner{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 40px;
                    transition-delay: 2s;
                    opacity: 1;
                    @media #{$large-mobile} {
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-start;
                    }
                    .rts-btn.btn-primary:hover{
                        border-color: #FFFFFF;
                    }
                    .btn-seconday{
                        border-color: #FFFFFF;
                        &:hover{
                            border-color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    &.banner-eight{
        background: url(../images/bg/coffee-banner.webp);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 170px 0 170px 0;
        position: relative;
        z-index: 1;
        animation: shrink 15s infinite alternate;
        @media(max-width:1200px){
            animation: none;
        }
        &::after{
            background: rgba(0, 0, 0, 0.3);
        }
    }
}

.rts-banner-main-area-swiper{
    .swiper-slide-active{
        .banner-shape-area{
            .shape-1{
                top: 0;
                left: 0;
                animation: slideInLeft .8s linear;
            }
            .shape-2{
                top: 0;
                left: 0;
                animation: slideInLeft 1s linear;
            }
        }
    }
}

.banner-two,
.banner-three{
    .banner-one-wrapper{
        text-align: center;
        span.b_one-pre{
            letter-spacing: 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            font-family: var(--font-secondary);
            color: var(--color-white);
            text-transform: uppercase;
            transition-delay: .3s;
            display: block;
            animation: fadeInUp2 1s ;
        }
        .banner-shape-area{
            display: flex;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 10px;
            transition-delay: 1s;
            animation: fadeInUp2 .5s;
            .shape{
                height: 10px;
                width: 10px;
                line-height: 10px;
                transform: rotate(45deg);
                margin: 0 6px;
                background: var(--color-primary);
                display: block;
            }
        }
        .title-banner{
            margin-top: 20px;
            font-weight: 400;
            margin-left: 0;
            margin-right: 0;
            font-size: 80px;
            line-height: 1.2;
            margin-bottom: 50px;
            font-family: var(--font-primary);
            transition-delay: .7s;
            @media #{$smlg-device} {
                font-size: 49px;
                line-height: 70px;
            }
            @media #{$md-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 45px;
            }
        }
        .button-area-banner{
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 40px;
            transition-delay: 1s;
            @media #{$large-mobile} {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            .rts-btn.btn-seconday{
                border-color: rgb(54, 54, 54);
                &:hover{
                    border-color: var(--color-primary);
                }
            }
            .rts-btn.btn-primary:hover{
                border-color: rgb(54, 54, 54);
            }
        }
    }
}
.rts-reservation-area2{
    background: #111111;
    .reservation-inner{
        padding: 50px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:768px){
            display: block;
        }
        .content{
            @media(max-width:768px){
                margin-bottom: 30px;
            }
        }
        .title{
            color: #FFFFFF;
            font-size: 50px;
            font-weight: 400;
            line-height: 55px;
            @media(max-width:991px){
                font-size: 40px;
                line-height: 50px;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                line-height: 56px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 45px;
            }
            @media(max-width:400px){
                font-size: 24px;
                line-height: 35px;
            }
        }
        .desc{
            font-size: 16px;
            text-transform: uppercase;
            margin: 0px 0px 0px 0px;
            @media(max-width:576px){
                font-size: 14px;
            }
        }
    }
}
.rts-breadcrumb-area{
    background: url(../images/breadcrumbs/about.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0 150px 0;
    overflow: hidden;
    .breadcrumb-inner{
        .banner-shape-area{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 30px;
            opacity: 1;
            transition-delay: .7s;
            .shape{
                height: 10px;
                width: 10px;
                line-height: 10px;
                transform: rotate(45deg);
                margin: 0 6px;
                background: var(--color-primary);
                display: block;
            }
        }
        .heading-title{
            color: #FFFFFF;
            font-size: 80px;
            font-weight: 400;
            line-height: 80px;
            @media(max-width:1200px){
                font-size: 70px;
                line-height: 70px;
            }
            @media(max-width:991px){
                font-size: 50px;
                line-height: 50px;
            }
            @media(max-width:768px){
                font-size: 40px;
                line-height: 40px;
            }
            @media(max-width:576px){
                font-size: 38px;
                line-height: 48px;
            }
            @media(max-width:450px){
                font-size: 32px;
                line-height: 32px;
            }
        }
        .main-title{
            color: #FFFFFF;
            font-size: 200px;
            line-height: 200px;
            font-weight: 400;
            margin: 100px 0px -215px 0px;
            @media(max-width:1200px){
                font-size: 175px;
                line-height: 175px;
                margin: 100px 0px -200px 0px;
            }
            @media(max-width:991px){
                font-size: 130px;
                line-height: 130px;
                margin: 75px 0 -185px 0;
            }
            @media(max-width:768px){
                font-size: 98px;
                line-height: 98px;
                margin: 75px 0 -125px 0;
            }
            @media(max-width:576px){
                font-size: 80px;
                line-height: 80px;
                margin: 75px 0 -120px 0;
            }
            @media(max-width:450px){
                font-size: 58px;
                line-height: 58px;
                margin: 75px 0 -115px 0;
            }
        }
    }
}
.home-one.our-chef{
    .rts-breadcrumb-area{
        background: url(../images/breadcrumbs/chef.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 200px 0 150px 0;
        overflow: hidden;
    }
}
.home-one.team-details{
    .rts-breadcrumb-area{
        background: url(../images/breadcrumbs/meet-chef.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 200px 0 150px 0;
        overflow: hidden;
    }
}
.home-one.reservation{
    .rts-breadcrumb-area{
        background: url(../images/breadcrumbs/reservation.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 200px 0 150px 0;
        overflow: hidden;
    }
}
.home-one.menu-tab{
    .rts-breadcrumb-area{
        background: url(../images/breadcrumbs/menu-1.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 200px 0 150px 0;
        overflow: hidden;
    }
}
.home-one.shop{
    .rts-breadcrumb-area{
        background: url(../images/breadcrumbs/shop.webp);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 200px 0 150px 0;
        overflow: hidden;
    }
}
.home-one.menu-tab,
.home-one.our-chef,
.home-one.reservation{
    .rts-breadcrumb-area .breadcrumb-inner .main-title{
        @media(max-width:1400px){
            font-size: 192px;
            line-height: 192px;
        }
        @media(max-width:1200px){
            font-size: 164px;
            line-height: 164px;
            margin: 100px 0 -195px 0;
        }
        @media(max-width:991px){
            font-size: 120px;
            line-height: 120px;
            margin: 100px 0 -180px 0;
        }
        @media(max-width:768px){
            font-size: 90px;
            line-height: 90px;
            margin: 100px 0 -120px 0;
        }
        @media(max-width:576px){
            font-size: 75px;
            line-height: 75px;
        }
        @media(max-width:450px){
            font-size: 53px;
            line-height: 53px;
            margin: 100px 0 -115px 0;
        }
    }
}

#dinenos-load {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #111111;
    z-index: 999999;
  }
  #dinenos-load .preloader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
  }
  #dinenos-load .preloader span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #111111;
    animation: preloader 1.3s linear infinite;
  }
  #dinenos-load .preloader span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
  }
  .loaded{
    display: none;
  }
  @keyframes preloader {
    0% {
      transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }

.lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--color-primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
